<template>
  <div>

  </div>
</template>
<script>
//import userLogin from "./userlogin";
// import codeLogin from "./codelogin";
import { mapGetters } from "vuex";
import settings from "@/settings.js";
import { onMounted } from "vue";
export default {
  name: "login",
  components: {
    //userLogin,
    //codeLogin,
  },
  data() {
    return {
      ruleForm2: {},
      activeName: "user",
      title: settings.title,
      copyright: settings.copyright,
    };
  },
  computed: {
    ...mapGetters(["website"]),
  },
  props: {
    logOnHuman: String,
  },
  mounted: () => {
   // console.log("aaaa")
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/login.scss";
</style>
